/* ===== Global CSS ===== */

html,
#root {
  scroll-behavior: smooth;
  height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  overflow: hidden;
}

#root {
  overflow-x: hidden;
  overflow-y: scroll;
}

:root {
  --placeholder-color: #d9d9d9;
}

.is_dark {
  --placeholder-color: #c1c1c1;
}

#model-viewer-3d {
  width: min(85vw, 600px) !important;
  height: min(85vw, 600px) !important;
}

.content-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--primary-color3) var(--bg-section2);
  /* For CSS Testing */
  /* outline: 1px solid red !important;  */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: var(--bg-section2);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color3);
  border-radius: 10px;
  border: 0;
}

*:disabled {
  opacity: 0.7;
  pointer-events: none;
}

i {
  transition: all 0.4s ease;
}

/* ===== Input Password Dot CSS ===== */

@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA)
    format('woff');
}

input[type='password']:not(:placeholder-shown) {
  font-family: 'password', 'Roboto', Helvetica, Arial, sans-serif;
}

/* ===== Transition ===== */

.content-enter {
  opacity: 0;
}

.content-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.content-exit {
  opacity: 1;
}

.content-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

.fadeOutUp {
  animation-name: fadeOutUp;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
}

/* ===== Sweet Alert ===== */

.sweet-alert-container {
  color: #fff !important;
  z-index: 999999 !important;
}

.sweet-alert-title {
  font-size: 24px !important;
  font-weight: bold !important;
}

.sweet-alert-popup {
  font-size: 16px !important;
  border-radius: 2rem !important;
}

.sweet-alert-confirm-btn,
.sweet-alert-cancel-btn {
  font-weight: bold !important;
  border-radius: 20px !important;
  padding: 10px 48px !important;
}

.sweet-alert-close-btn {
  width: 42px;
  height: 42px;
}

.sweet-alert-close-btn:hover {
  border: none !important;
}

.sweet-alert-close-btn:focus,
.sweet-alert-close-btn:focus-visible {
  color: #ccc !important;
  border: none !important;
  box-shadow: 0 0 0 0 !important;
}

/* ===== Plyr Player ===== */
:root {
  --plyr-color-main: var(--primary-color3);
  --plyr-video-background: rgba(0, 0, 0, 0);
  --plyr-menu-radius: 5px;
}

.plyr {
  width: min(85vw, 600px) !important;
  border-radius: 10px !important;
}

.plyr__controls__item,
.plyr__control {
  border: none !important;
}

@media (max-width: 450px) {
  .plyr__volume {
    display: none !important;
  }
}

/* Gradient Border */
.z-0 {
  z-index: 0;
}

.gradient-border {
  --borderWidth: 0.45em;
  background: transparent;
  position: relative;
  border-radius: var(--borderWidth);
}

.gradient-border:before {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: calc(2 * var(--borderWidth));
  z-index: -2;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}

.gradient-border::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--primary-color);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
