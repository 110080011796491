#multiselectContainerReact {
  max-width: 600px;
  border-radius: 6px;
  background: var(--primary-color);
  color: var(--primary-color2);
  border: 1px solid var(--primary-color9);
  font-weight: 400;
}

#multiselectContainerReact .searchWrapper {
  border: none;
}

#multiselectContainerReact .searchBox {
  width: auto;
  padding: 5px;
  line-height: normal;
}

#multiselectContainerReact .searchBox:focus {
  border: 0;
}

#multiselectContainerReact .searchBox::placeholder {
  color: var(--primary-color2);
}

#multiselectContainerReact .optionListContainer {
  background-color: var(--primary-color);
  color: var(--primary-color2);
  font-size: 1.5rem;
}

#multiselectContainerReact .chip {
  background: var(--primary-color3);
}

#multiselectContainerReact .groupChildEle {
  display: flex;
}

#multiselectContainerReact .groupHeading {
  font-weight: bold;
}

@media (max-width: 612px) {
  #multiselectContainerReact .searchBox {
    width: 100%;
  }
}
